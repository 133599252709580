<script>
import Layout from "@/router/layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import axios from "axios";
import Swal from "sweetalert2";
// import moment from 'moment';
import Cookies from "js-cookie";

/**
 * Dashboard Component
 */
export default {
  page: {
    title: "List Draft",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      title: "List Booking Slot",
      items: [
        {
          text: "Home",
          href: "/",
        },
        {
          text: "Surat Keluar",
          href: "/surat-keluar",
        },
        {
          text: "Draft",
          active: true,
        },
      ],
      // variable Page Table
      loadingTable: true,
      dataTable: [],
      pagingTable: [],
      showDataToTable: "",
      showDataFromTable: "",
      totalDataTable: "",
      currentTablePage: "",
      searchDataTable: "",
      search: "",
      table_data: [],
      role_slug: Cookies.get("session_role_slug"),
      departemen: Cookies.get("session_departemen"),
    };
  },
  mounted() {
    this.getDataTable();
  },
  methods: {
    getDataTable() {
      let self = this;
      self.loadingTable = true;
      axios
        .get(
          process.env.VUE_APP_BACKEND_URL_VERSION +
            "/api/arsip-dokumen/suratkeluar/getdatabooking?role_slug=" +
            Cookies.get("session_role_slug") +
            "&id=" +
            Cookies.get("session_id") +
            "&search=" +
            self.search +
            "&departemen=" +
            self.departemen
        )
        .then((response) => {
          var response_data = response.data;
          if (response_data.code == 200) {
            //console.log(response_data.list_data)
            self.dataTable = response_data.list_data;
            self.loadingTable = false;
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: response_data.message,
            });
          }
        });
    },
    hapusData(id) {
      var alert_text = "Apakah Anda yakin?";
      Swal.fire({
        title: "Warning ?",
        text: alert_text,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ya",
        cancelButtonText: "Tidak",
      }).then((result) => {
        if (result.isConfirmed) {
          let self = this;
          Swal.fire({
            title: '<i class="fas fa-spinner fa-spin"></i>',
            text: "Loading...",
            showConfirmButton: false,
          });
          var FormData = require("form-data");
          var data = new FormData();
          data.append("id", id);

          var config = {
            method: "post",
            url:
              process.env.VUE_APP_BACKEND_URL_VERSION +
              "/api/arsip-dokumen/suratkeluar/delete",
            data: data,
          };
          axios(config)
            .then(function (response) {
              console.log(response);
              let timerInterval;
              Swal.fire({
                icon: "success",
                title: "Berhasil",
                text: "Anda akan diarahkan ke halaman arsip dokumen surat keluar segera",
                timer: 2000,
                timerProgressBar: true,
                showCancelButton: false,
                showConfirmButton: false,
                willClose: () => {
                  clearInterval(timerInterval);
                },
              }).then((result) => {
                /* Read more about handling dismissals below */
                if (result.dismiss === Swal.DismissReason.timer) {
                  Swal.close();
                  self.getDataTable();
                }
              });
            })
            .catch((e) => {
              this.axiosCatchError = e.response.data.data;
              Swal.close();
            });
        } else {
          Swal.close();
        }
      });
    },
  },
};
</script>
<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row mb-2">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-center">
        <ul class="list-group list-group-horizontal">
          <li class="col-md-6 list-group-item fs-5">
            <router-link :to="{ name: 'all-surat_keluar' }" class="text-dark">
              <i class="bx bx-mail-send"> </i> Surat Keluar
            </router-link>
          </li>
          <li class="col-md-6 list-group-item fs-5 active">
            <router-link :to="{ name: 'surat_keluar_booking' }" class="text-light">
              <i class="bx bx-list-ol fs-5"> </i> Booking Slot
              <span class="badge bg-primary rounded-pill">{{ total_draft }}</span>
            </router-link>
          </li>
        </ul>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-md-12">
                <div class="row" v-if="role_slug != 'kepala_divisi' && role_slug != 'officer'">
                  <div class="col-md-12 text-end">
                    <router-link
                      :to="{ name: 'add-surat_keluar', params: { tipe: 'booking' }}"
                      class="btn btn-info mb-2 rounded-0"
                      ><i class="fa fa-plus"></i> Tambah Surat
                      Keluar</router-link
                    >
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-9"></div>
                  <div class="col-md-3">
                    <input
                      type="text"
                      class="form-control"
                      id="searchTable"
                      v-model="search"
                      @input="getDataTable()"
                      placeholder="Cari Data ..."
                    />
                  </div>
                </div>
                <br />
              </div>
              <div class="col-md-12">
                <div class="table-responsive">
                  <table class="table mb-0 table-bordered table-condensed table-hover table-striped">
                    <thead>
                      <tr>
                        <th class="text-center text-white" style="width: 50px;background-color: #132d4a">No</th>
                        <th class="text-center text-white" style="width: 150px;background-color: #132d4a">Tanggal</th>
                        <th class="text-center text-white" style="width: 150px;background-color: #132d4a">Signer</th>
                        <th class="text-center text-white" style="width: 150px;background-color: #132d4a">Nomor Surat</th>
                        <th class="text-center text-white" style="width: 150px;background-color: #132d4a">Dikirimkan Kepada</th>
                        <th class="text-center text-white" style="width: 175px;background-color: #132d4a">Perihal</th>
                        <th class="text-center text-white" style="width: 175px;background-color: #132d4a">PIC & No Telp</th>
                        <th class="text-center text-white" style="width: 175px;background-color: #132d4a">Aksi</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-if="loadingTable">
                        <td colspan="8">
                          <i class="fas fa-spinner fa-spin"></i> Loading...
                        </td>
                      </tr>
                      <tr v-else-if="dataTable == ''">
                        <td class="text-center" colspan="8">
                          Data Tidak Tersedia
                        </td>
                      </tr>
                      <tr
                        v-else
                        v-for="(row_data, key_data) in dataTable"
                        :key="key_data"
                      >
                        <td class="text-center">
                          {{ key_data + 1 }}
                        </td>
                        <td>
                          {{ row_data.ad_tanggalsurat }}
                        </td>
                        <td>{{ row_data.ad_tandatangan }}</td>
                        <td>
                          {{ row_data.ad_nomorsurat }}
                        </td>
                        <td>{{ row_data.ad_dikirim }}</td>
                        <td>{{ row_data.ad_perihal }}</td>
                        <td>{{ row_data.ad_pic }} - {{ row_data.ad_notelp }}</td>
                        <td class="text-center">
                          <div class="btn-group">
                            <router-link
                              :to="{
                                name: 'edit-surat_keluar',
                                params: { tipe: 'surat_keluar', id: row_data.ad_id },
                              }"
                              class="btn btn-sm btn-info"><i class="fa fa-edit"></i> Edit</router-link
                            >
                            <button
                              type="button"
                              class="btn btn-sm btn-danger"
                              v-on:click="hapusData(row_data.ad_id)"
                            >
                              <i class="fa fa-trash"></i> Hapus
                            </button>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
